<template>
    <div class="references-content">
        <v-container id="intro" class="cyan text-center white--text" fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" md="6">
                    <h1 class="mb-5"  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">Referenzen</h1>
                    <p class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">
                        Hier kannst Du Projekte sehen an deren Umsetzung ich beteiligt war.
                            Von einfachen Single Page Websites, über Websites und Blogs, bis hin zu Webshops gibt es einiges zu entdecken.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select
                        v-model="selectedTypeTags"
                        :items="typeTags"
                        chips
                        clearable
                        deletable-chips
                        dense
                        label="Referenzen nach Typ filtern:"
                        multiple
                        small-chips
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        v-model="selectedTechnologyTags"
                        :items="technologyTags"
                        chips
                        clearable
                        deletable-chips
                        dense
                        label="Referenzen nach Technologie filtern:"
                        multiple
                        small-chips
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <v-btn
                        v-for="(reference, i) in activeReferences"
                        :key="i"
                        class="ma-1"
                        color="primary" outlined small
                        @click="$vuetify.goTo('#' + reference.anchor, scrollOptions)"
                    >
                        {{reference.title}}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row align="stretch">
                <v-col
                    v-for="(reference ,i) in activeReferences"
                    :key="i"
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3"
                >
                    <info-card-vertical :contentProp="reference" />
                </v-col>
            </v-row>
            <v-row v-if="activeReferences.length == 0">
                <v-col class="text-center" cols="12" md="6" offset-md="3">
                    <v-card>
                        <v-card-title>
                            Leider gibt es keine Referenzen die zu Ihren Filterkriterien passen. Versuchen Sie es nochmal mit weniger oder anderen Filtern.
                        </v-card-title>
                        <v-card-actions>
                            <v-btn block color="primary" @click="resetFilters">Filter zurücksetzen</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>
import InfoCardVertical from "../components/InfoCardVertical.vue";

export default {
    name: "References",
    components: {
        InfoCardVertical
    },
    computed: {
        scrollOptions () {
            return {
            duration: 700,
            offset: 0,
            easing: 'easeInOutCubic',
            }
        },
        technologyTags() {
            var technologyTags = [];
            for (let reference of this.references) {
                for (let tag of reference.tags) {
                    if (!technologyTags.includes(tag.title) && tag.category == 'technology') {
                        technologyTags.push(tag.title)
                    }
                }
            }
            technologyTags.sort();
            return technologyTags;
        },
        typeTags() {
            var typeTags = [];
            for (let reference of this.references) {
                for (let tag of reference.tags) {
                    if (!typeTags.includes(tag.title) && tag.category == 'type') {
                        typeTags.push(tag.title)
                    }
                }
            }
            typeTags.sort();
            return typeTags;
        },
        allSelectedTags()   {
            return this.selectedTechnologyTags.concat(this.selectedTypeTags);
        },
        activeReferences: function() {
            if(this.selectedTechnologyTags.length == 0 && this.selectedTypeTags.length == 0){
                return this.references;
            }
            
            var activeReferences = [];
            var filters = this.allSelectedTags;
            
            this.references.forEach(function(reference) {
                function referenceContainsFilter(filter) {
                    return reference.tags.some(obj => obj.title === filter);
                }
                if(filters.every(referenceContainsFilter)) {
                    activeReferences.push(reference);
                }
            });
            
            return activeReferences;
        }
    },
    methods:    {
        resetFilters() {
            this.selectedTechnologyTags = '';
            this.selectedTypeTags = '';
        }
    },
    data: () => ({
        selectedTechnologyTags: [],
        selectedTypeTags: [],
        references:  [
            {
                title: 'AE Projektmanagement',
                text: `
                    Für AE Projektmanagement war eine minimalistische Webseite gefragt. Mit nur zwei Farben, zwei Schriften und einfachen Linien und Rahmen entstand eine übersichtliche und schnelle Webseite.
                    Durch Verzicht auf unnötige Funktionen ist die Webseite von AE Projektmanagement auch sehr datensparsam und frei von Cookies oder anderen Tracking-Methoden.
                `,
                link: 'https://ae-projektmanagement.com/',
                image: 'images/references/reference-ae-projektmanagement.webp',
                anchor: 'ae-projektmanagement',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#minimalist',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#nocookies',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'aha – Jugendinfozentrum Vorarlberg',
                text: `
                    Das aha verwendet eine Website mit Informationen für Jugendliche auf der Basis von Wordpress.
                    Zusätzlich gibt es eine Vue.js App in der Jugendliche Quests erledigen können um Punkte zu sammeln und sich Belohnungen zu verdienen.
                `,
                link: 'https://www.aha.or.at/',
                image: 'images/references/reference-aha-vorarlberg.jpg',
                anchor: 'aha-jugendinfozentrum',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#app',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    },
                    {
                        title: '#vuejs',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'aha - Liechtenstein',
                text: '\
                    aha Lichtenstein ist eine Variante der aha Vorarlberg Wordpress-Installation und verwendet das selbe Design mit den selben Funktionalitäten.\
                ',
                link: 'https://www.aha.li/',
                image: 'images/references/reference-aha-liechtenstein.jpg',
                anchor: 'aha-liechtenstein',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            {
                title: 'Arte Verde',
                text: `
                    Die Duftmanufaktur Arte Verde hatte bereits eine ältere sehr komplexe Webseite mit internem Bereich, Kundeninformationen und Bestellformularen.
                     Für den Relaunch haben wir ein schlichtes aber modernes Design gewählt, daß auf allen Geräten funktioniert.
                      Außerdem wurde der umfangreiche Produktkatalog and Duftölen und Essenzen mit Hilfe von Woocommerce (ohne Kauffunktion) abgebildet.
                `,
                link: 'https://arteverde.at/',
                image: 'images/references/reference-arteverde.webp',
                anchor: 'arteverde',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Aurora Confectionery',
                text: `
                    Die leckeren Zuckermandeln von Aurora Confectionery wurden bereits über eine Woocommerce-Webshop verkauft,
                     allerdings hatte dieser mit starken Performance-Problemen zu kämpfen.
                      Wir haben den Webshop komplett neu mit Avada und minimalem Plugin-Einsatz erstellt und gleichzeitig das Design aufgefrischt.
                `,
                link: 'https://aurora-confectionery.com/',
                image: 'images/references/reference-aurora.webp',
                anchor: 'arteverde',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Biohof Nofels',
                text: `
                    Auch der traditionelle Bio-Gemüsebauernhof muss heute Online auffindbar sein, aber zu umständlich soll das nicht sein.
                    Deshalb gibt es auch hier ein kleine aber feine Single-Page-Webseite mit den wichtigsten Daten.
                    So daß man das beste Gemüse in Vorarlberg (der einzige demeter-Betrieb für Gemüse) auf findet.
                `,
                link: 'https://biohof-nofels.at/',
                image: 'images/references/reference-biohof-nofels.webp',
                anchor: 'biohof-nofels',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#minimalist',
                        category: 'type'
                    },
                    {
                        title: '#singlepage',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#nocookies',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Bio-Restaurant Moritz',
                text: `
                    Die Webseite des Bio-Restaurant Moritz hat ein einzigartiges Design von Gestaltungssache implementiert in Wordpress.
                    Es ist problemlos möglich individuelle Designs mit der Funktionalität eines CMS zu kombinieren, inkl. Blog & Formular.
                `,
                link: 'https://biomoritz.at/',
                image: 'images/references/reference-biomoritz.jpg',
                anchor: 'biomoritz',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'BREAK. Media Solutions',
                text: '\
                    Die BREAK. Media Solutions Website basiert auf dem hauseigenen CMS BREAK.Fast.\
                    Mit BREAK.Fast lassen sich individuelle Designs schnell und flexibel umsetzen.\
                ',
                link: 'https://www.break-media.com/',
                image: 'images/references/reference-break-media.jpg',
                anchor: 'break-media',
                tags: [
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'Breddy\'s - Crossover Pants',
                text: '\
                    Der Webshop von Breddy\'s basiert auf dem kombinierten Webshop & CMS BREAK.Prime.\
                    Mit BREAK.Prime sind individualisierte Shoplösungen für alle Ansprüche möglich.\
                ',
                link: 'https://www.breddys.com/',
                image: 'images/references/reference-breddys-crossoverpants.jpg',
                anchor: 'breddys-crossover-pants',
                tags: [
                    {
                        title: '#breakprime',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#materialdesign',
                        category: 'technology'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#vuejs',
                        category: 'technology'
                    },
                    {
                        title: '#vuetify',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'danialu Deutschland - Bauelemente',
                text: '\
                    Die Website von danialu Deutschland basiert auf BREAK.Fast mit einem individuellem Theme und speziellen Plugins, wie Wiki, News & verschiedenen Navigationen.\
                ',
                link: 'https://www.danialu.de/',
                image: 'images/references/reference-danialu-bauelemente.jpg',
                anchor: 'danialu-bauelemente',
                tags: [
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            {
                title: 'Digisam - Digital Signage',
                text: `
                    Die Digisam-Webseite bestand bereits mit einem modernem Design und tollen Inhalten.
                     Allerdings war das zugrundeliegende CMS schwer zu bedienen und unflexibel.
                      Deshalb wurde die Webseite mit Wordpress neu erstellt, Design und Inhalte übertragen und die Kunden so eingeschult,
                       daß sie ihre Webseite selbst bedienen können.
                `,
                link: 'https://digisam.at/',
                image: 'images/references/reference-digisam.webp',
                anchor: 'digisam',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Diskurs - Fachmagazin Jugendarbeit',
                text: '\
                    Der Diskurs Blog informiert Jugendliche & Jugendarbeiter zu aktuellen Themen. Der Blog basiert auf Wordpress.\
                ',
                link: 'https://www.jugend-diskurs.at/',
                image: 'images/references/reference-jugend-diskurs.jpg',
                anchor: 'jugend-diskurs',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            {
                title: 'DQS Consulting',
                text: `
                    DQS Consulting bietet Beratung im Bereich Arbeitssicherheit. Bei diesem Thema ist Klarheit besonders wichtig.
                     Passend dazu zeichnet sich die Webseite durch ein klares und übersichtliches Design aus, das Nutzer intuitiv durch die verschiedenen Leistungsbereiche führt.
                `,
                link: 'https://dqs-consulting.at/',
                image: 'images/references/reference-dqs-consulting.webp',
                anchor: 'dqs-consulting',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'El.An.Rea',
                text: '\
                    BREAK.Fast\
                ',
                link: 'https://www.elanrea.com/',
                image: 'images/references/rock-texture.jpg',
                anchor: 'elanrea',
                tags: [
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            {
                title: 'Entwicklungs Impuls - Unternehmensberatung',
                text: `
                    Die Webseite für die Unternehmensberatung Entwicklungs Impuls ist in intensiver Zusammenarbeit mit dem Kunden und Caro Pfister von Studio Caro enstanden.
                     Mit einer Designerin an meiner Seite werden die Webseiten nochmal auf ein neues gestalterlisches Niveau gehoben.
                      Die derzeitige Single-Page-Webseite wird noch auf eine ausführliche Webseite inklusive Blog ausgebaut.
                `,
                link: 'https://entwicklungs-impuls.com/',
                image: 'images/references/reference-entwicklungs-impuls.webp',
                anchor: 'entwicklungs-impuls',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#singlepage',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Extrabrut - Champagner & Schaumweine',
                text: '\
                    Magento\
                ',
                link: 'https://www.extrabrut.at/',
                image: 'images/references/rock-texture.jpg',
                anchor: 'extrabrut-champagner-schaumweine',
                tags: [
                    {
                        title: '#magento',
                        category: 'technology'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'Fachhochschule Vorarlberg',
                text: '\
                    Basierend auf Typo3 präsentiert die Website der Fachhochschule Vorarlberg Ihre Studiengänge, Veranstaltungen & weitere Informationen.\
                    Das Theme ist speziell für die FHV entworfen und basiert auf Bootstrap.\
                ',
                link: 'https://www.fhv.at/',
                image: 'images/references/reference-fhv.jpg',
                anchor: 'fh-vorarlberg',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#typo3',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Federmann Veranstaltungsaal',
                text: '\
                    Der Federmannsaal ist die Schwester-Webseite zum Bio-Restaurant Moritz. Ein durchgängiges Design zwischen beiden Webseiten erleichtert die Orientierung.\
                    Aber trotzdem ist der Federmannsaal mit eigenem Farbschema abgehoben. Und ein ausführliches Reservierungsformular ist auch dabei.\
                ',
                link: 'https://federmannsaal.at/',
                image: 'images/references/reference-federmannsaal.jpg',
                anchor: 'federmannsaal',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Friedensräume Lindau',
                text: '\
                    BREAK.Fast\
                ',
                link: 'https://www.friedens-raeume.de/',
                image: 'images/references/rock-texture.jpg',
                anchor: 'friedensraeume-lindau',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            {
                title: 'FSD Automobile',
                text: `
                    Das Design von FSD Automobile ist modern und übersichtlich, mit einer klar strukturierten Navigation und Call-to-Action-Elementen, die den Besuchern eine einfache Orientierung ermöglicht.
                     Die Verwendung von Wordpress mit Avada ermöglicht geplante Erweiterungen, wie interne Bereiche und einen B2B-Shop für Fahrzeug-Angebote.
                `,
                link: 'https://fsd-automobile.de/',
                image: 'images/references/reference-fsd-automobile.webp',
                anchor: 'fsd-automobile',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Gourmet & Papeterie',
                text: '\
                    Gourmet & Papeterie verkauft ausgewählte Delikatessen in Bregenz vor Ort und im Webshop im deutschsprachigen Raum.\
                    Mit Hilfe von Plugins lassen sich in WooCommerce auch komplexe Steuer- & Versandszenarien abbilden wie hier für die Lebensmittel & Weine.\
                ',
                link: 'https://gourmetundpapeterie.at/',
                image: 'images/references/reference-gourmetundpapeterie.webp',
                anchor: 'gourmetundpapeterie',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#laendlekassa',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Grillshop',
                text: '\
                    Grillshop betreibt einen Webshop auf der Basis von Magento.\
                    Um alle benötigten Funktionen abzudecken werden hier Plugins für Zahlungsarten & Warenwirtschaft verwendet.\
                ',
                link: 'https://www.grillshop.at/',
                image: 'images/references/reference-grillshop.jpg',
                anchor: 'grillshop',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#magento',
                        category: 'technology'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    }
                ]
            },*/
            {
                title: 'Gsimedia - Digitale Werbeflächen',
                text: `
                    Die Firma Digisam bietet nicht nur digitale Werbebildschirme, sondern auch die Vermietung der Werbeflächen auf diesen Bildschirmen an.
                     Um die Buchung zu vereinfachen haben wir einen Shop mit Buchungsmöglichkeit und Darstellung der Werbestandorte auf einer Karte umgesetzt.
                `,
                link: 'https://gsimedia.at/',
                image: 'images/references/reference-gsimedia.webp',
                anchor: 'gsimedia',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#booking',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Hausmeister Mario',
                text: `
                    Bei einer Neugründung möchte man schnell online sichtbar sein, hat aber oft noch nicht genug Inhalte.
                     Hier ist eine Onepage-Webseite eine gute Lösung. So finden Kunden jederzeit die wichtigsten Infos.
                      Für Hausmeister Mario wurde die Onepage-Webseite mit den Kontaktdaten, Leistungen, Fotos und ein Google Business Eintrag erstellt.
                `,
                link: 'https://hausmeister-mario.at/',
                image: 'images/references/reference-hausmeister-mario.webp',
                anchor: 'hausmeister-mario',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#singlepage',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Hotel Schloss Hofen',
                text: '\
                    Das Seminarhotel Schloss Hofen präsentiert auf einer BREAK.Fast Website die vielfältigen Hotelzimmer und Seminarräume.\
                    Das Design harmoniert mit dem der Hauptseite von Schloss Hofen.\
                ',
                link: 'https://www.hotelschlosshofen.at/',
                image: 'images/references/reference-hotel-schlosshofen.jpg',
                anchor: 'hotel-schloss-hofen',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'Josef Otten',
                text: '\
                    Mit einem modernen Theme wird Wordpress zum Showroom für exklusive Stoffe von Josef Otten.\
                    Animierte Galerien und kurze Videos bringen die Stoffe zum Greifen nah.\
                ',
                link: 'https://www.josefotten.com/',
                image: 'images/references/reference-josef-otten.jpg',
                anchor: 'josef-otten',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            {
                title: 'JUKA\'s - Feines aus Vorarlberg',
                text: `
                    Der Feinkostladen JUKA's mit Spezialitäten aus Vorarlberg brauchte eine Webseite die ihre köstlichen Produkte gut zur Geltung bringt.
                     Mit einem Design von der teamwork werbeagentur ist das garkein Problem.
                      Die Gestaltung zieht sich vom Laden, über die Drucksachen bis hin zur Webseite durch.
                       So bekommt JUKA's einen hohen Wiedererkennungswert.
                `,
                link: 'https://jukas.at/',
                image: 'images/references/reference-jukas.webp',
                anchor: 'jukas',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Kässtecherei',
                text: `
                    In der Kässtecherei gibt es feinen Käse aus Vorarlberg und andere Delikatessen.
                     Auf der Webseite werden das Sortiment und das Geschäft vorgestellt.
                      Außerdem gibt es Kontaktmöglichkeiten für Geschenkkörbe, Gutscheine und weitere Informationen.
                       Eine klassische Webseite für ein kleines Unternehmen.
                `,
                link: 'https://kaesstecherei.at/',
                image: 'images/references/reference-kaesstecherei.webp',
                anchor: 'kaesstecherei',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Körpertherapie Vorarlberg',
                text: '\
                    Auch ältere Websites können modernisiert werden ohne bestehendes Design und Technik komplett über Bord zu werfen.\
                    Mit BREAK.Fast wurde bei der Körpertherapie Vorarlberg auf ein modernes Grid und Responsive Design umgestellt.\
                ',
                link: 'https://www.koerpertherapie-vorarlberg.at/',
                image: 'images/references/reference-koerpertherapie.jpg',
                anchor: 'koerpertherapie-vorarlberg',
                tags: [
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'Kunsthaus Bregenz',
                text: `
                    Typo3 ist ein mächtiges CMS um wie beim Kunsthaus Bregenz einen großen Katalog an Ausstellungen zu präsentieren & archivieren.
                    Mit einem reduziertem Website-Design kommen die Werke der Künstler umso mehr zur Geltung.
                `,
                link: 'https://www.kunsthaus-bregenz.at/',
                image: 'images/references/reference-kub.jpg',
                anchor: 'kunsthaus-bregenz',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#typo3',
                        category: 'technology'
                    }
                ]
            },*/
            {
                title: 'Larva - Historische Schneiderei',
                text: '\
                    Bei der Website für Larva werden mit Hilfe von Wordpress & Woocommerce ein Blog und ein Webshop kombiniert.\
                    So kann man sowohl Neuigkeiten und Informationen teilen, als auch Produkte verkaufen.\
                ',
                link: 'https://www.larva.eu/',
                image: 'images/references/reference-larva.jpg',
                anchor: 'larva-historische-schneiderei',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'LebensRAUM - Coaching',
                text: `
                    Auch vor der Unternehmensgründung kann es sinnvoll sein vorab Domains zu reservieren.
                     Und damit unter der Domain keine generische Platzhalterseite auftaucht, kann man erste Daten online stellen, und ist damit bereits online präsent.
                      Das habe ich für das Coachingunternehmen LebensRAUM schnell und unkompliziert umgesetzt.
                       Im Hintergrund steckt bereits Wordpress, so daß die Webseite jederzeit ausgebaut werden kann.
                `,
                link: 'https://sonja-geller.de/',
                image: 'images/references/reference-lebensraum.webp',
                anchor: 'lebensraum',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#placeholder',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Marktgemeinde Nenzing',
                text: '\
                    Mit BREAK.Fast lassen sich auch umfangreiche Websites realisieren.\
                    Die Marktgemeinde Nenzing bietet Bürgerinformationen, Formulardownloads, einen Mitgliedsbereich für Vereine und vieles mehr.\
                ',
                link: 'https://www.marktgemeinde-nenzing.com/',
                image: 'images/references/reference-marktgemeinde-nenzing.jpg',
                anchor: 'marktgemeinde-nenzing',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'Mediashop',
                text: '\
                    Mediashop basiert auf einer stark angepassten Version von BREAK.Prime so daß dem Kunden viele Einkaufsmöglichkeiten zur Verfügung stehen.\
                ',
                link: 'https://www.mediashop.tv/',
                image: 'images/references/reference-mediashop.jpg',
                anchor: 'mediashop',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#breakprime',
                        category: 'technology'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'Merlin Unternehmensberatung',
                text: '\
                    Für die Merlin Unternehmensberatung wurde ein individuelles Theme mit modernen Animationen in BREAK.Fast implementiert.\
                    Mit großflächigen Bildern und prägnanten Schriftarten werden so die Geschäftsfelder vorgestellt.\
                ',
                link: 'https://www.merlin-ub.at/',
                image: 'images/references/reference-merlin-ub.jpg',
                anchor: 'merlin-unternehmensberatung',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'mwm.gold',
                text: `
                    Als Edelmetallhändler braucht man eine seriöse und informative Webseite um Edelmetalle als Geldanlage zu erläutern.
                     Verschiedene Infografiken über Edelmetalle und eine ausführliche Vorstellung des Teams sorgen für Vertrauen.
                      Für Mitarbeiter und Kunden ist auch ein interner Bereich mit weiterführenden Unterlagen eingerichtet.
                `,
                link: 'https://mwm.gold/',
                image: 'images/references/reference-mwmgold.webp',
                anchor: 'mwmgold',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#intranet',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            {
                title: 'Naturwacht Vorarlberg',
                text: '\
                    Die Naturwacht Vorarlberg benötigte einen internen Mitgliederbereich und ist deshalb von einem Homepagebaukasten zu Wordpress gewechselt.\
                    So können Neuigkeiten intern und extern leicht kommuniziert werden. Und auch die einzelnen Ortsgruppen können sich als Autoren einbringen.\
                ',
                link: 'https://naturwacht-vorarlberg.at/',
                image: 'images/references/reference-naturwacht.jpg',
                anchor: 'naturwacht',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#intranet',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Orbit SciFi HTML/CSS Theme',
                text: `Basierend auf dem Orbit SciFi UI Kit wurde eine Bootstrap Theme für ein Browsergame entwickelt.
                        Mit modernem CSS (Transparenzen, Verläufen, etc.) entstand ein komplett andersartiges Design.`,
                link: 'https://orbit.die-webmacherin.at/',
                image: 'images/references/reference-orbit-theme.jpg',
                anchor: 'orbit-theme',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#browsergame',
                        category: 'type'
                    },
                    {
                        title: '#html/css',
                        category: 'technology'
                    },
                    {
                        title: '#theme',
                        category: 'type'
                    }
                ]
            },
            /*{
                title: 'Polyden - Industriefolien',
                text: '\
                    Für Polyden wurde mit BREAK.Prime eine Unternehmenswebsite umgesetzt, die später auch um einen Webshop erweitert werden soll.\
                    Die umfangreichen CMS-Funktionen heben BREAK.Prime von anderen Webshop-Lösungen ab.\
                ',
                link: 'https://www.polyden.de/',
                image: 'images/references/reference-polyden.jpg',
                anchor: 'polyden-industriefolien',
                tags: [
                    {
                        title: '#breakprime',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#materialdesign',
                        category: 'technology'
                    },
                    {
                        title: '#vuejs',
                        category: 'technology'
                    },
                    {
                        title: '#vuetify',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Propago - Grow LEDs',
                text: '\
                    Magento\
                ',
                link: 'https://www.propago.at/',
                image: 'images/references/rock-texture.jpg',
                anchor: 'propago-grow-leds',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#magento',
                        category: 'technology'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    }
                ]
            },*/
            {
                title: 'PhoenixDx - Procomcure Medizinprodukte',
                text: `
                    Die Produktseite für das PhoenixDx-Gerät von Procomcure wurde ursprünglich mit Elementor erstellt.
                     Für den Relaunch habe ich auf Avada gewechselt und das Design angepasst, um die Lesbarkeit zu verbessern.
                      WooCommerce wird im Katalogmosud verwendet um die Produkte vorzustellen und Zubehör miteinander zu verknüpfen.
                `,
                link: 'https://phoenixdx.at/',
                image: 'images/references/reference-phoenixdx.webp',
                anchor: 'phoenixdx',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'provinzenz Intranet',
                text: `
                    provinzenz ist eine gemeinnützige Organisation für bedarfsgerechte Wohnangebote & Dienstleistungen für Menschen mit Beeinträchtigungen.
                     Für die Betreuung der Bewohner gibt es umfangreiche Dokumentation für die Mitarbeiter.
                      Auch umfangreiche Informationen lassen sich übersichtlich in Wordpress darstellen.
                       Mit einem Plugin für Zugriffsverwaltung wird die Webseite so zu einem vollwertigem Intranet.
                        Die Leitungspersonen bei provinzenz können das Intranet selbtständig mit Inhalten befüllen und erweitern.
                `,
                /*link: 'https://provinzenz.die-webmacherin.at/',*/
                image: 'images/references/reference-provinzenz.webp',
                anchor: 'provinzenz',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#intranet',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Punkt 3 - Bild und Ton',
                text: `
                    Auch bestehende Webseiten können neu gestaltet werden, ohne das der Inhalt verloren geht.
                     Für Punkt 3 habe ich auf Avada als Theme gewechselt und ein neues Design von teamwork werbung implementiert.
                      Die Webseite bietet umfangreiche & informative Inhalte die durch Landingpages und ein komplexes, aber übersichtliches Menü zugänglich gemacht wurden.
                `,
                link: 'https://punkt3.li/',
                image: 'images/references/reference-punkt3.webp',
                anchor: 'punkt3',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type',
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Rosales - belts & more',
                text: `
                    Die edlen italienischen Ledergürtel wirken auf dem schlichten Design von teamwork Werbung besonders gut.
                     Mit professionellen Fotos & liebevollen Texten wird der Webauftritt abgerundet.
                      Die Gürtel gibt es in verschiedenen Größen, Farben und mit unterschiedlichen Schnallen.
                       Alle Optionen werden übersichtlich mit Vorschaubild (sowohl im Produktbild, als auch bei den Optionen) dargestellt.
                `,
                link: 'https://rosales.at/',
                image: 'images/references/reference-rosales.webp',
                anchor: 'rosales',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Scenography & Costumedesign by Lisa',
                text: `
                    Wenn Zeit oder Geld fehlen um eine umfangreiche Website zu erstellen, bieten sich Single Page Websites als professionelle Lösung an.
                     So werden die wichtigsten Informationen ohne unnötigen Aufwand ansprechend präsentiert.
                      Für Scenography & Costumedesign by Lisa, kann die Kundin selbst neue Projekte hinzufügen und ihre Referenzen auf dem neuesten Stand halten.
                       Gleichzeitig muss sie aber keine umfangreiche Webseite verwalten, nur genau das was notwendig ist.
                `,
                link: 'https://scenography-and-costumedesign-by-lisa.de/',
                image: 'images/references/reference-scenes-and-costumes.jpg',
                anchor: 'scenography-and-costumes',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#portfolio',
                        category: 'type',
                    },
                    {
                        title: '#singlepage',
                        category: 'type',
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Siebdruck D\'Angelo',
                text: `
                    Die einzige Siebdruckerei in Vorarlberg wollte von eine Online-Baukasten auf Wordpress umsteigen.
                     Ich übernehme hier auch das technische Drumherum, wie Hosting bestellen, Daten umziehen und alte Services kündigen.
                      Damit für die neu gergründete Firma keine allzu hohen kosten anfallen, haben wir ein vorgefertigtes Design gewählt.
                       Von mir gibt es Platzhalterseite, Struktur und Einstellungen, die ausführlichen Inhalte kann der Kunde selbst in Ruhe erstellen.
                `,
                link: 'https://siebdruckdangelo.com/',
                image: 'images/references/reference-dangelo.webp',
                anchor: 'dangelo',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#singlepage',
                        category: 'type',
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Schatzmann Desinfektion',
                text: '\
                    Die Webseite für Schatzmann Desinfektion in Feldkirch kombiniert ein profesionelles vorgefertigtes Design mit individuellen Inhalten.\
                    So ensteht zügig eine aussagekräftige Online-Firmenpräsenz ohne generisch zu wirken.\
                ',
                link: 'https://schatzmann-desinfektion.at/',
                image: 'images/references/reference-schatzmann.webp',
                anchor: 'schatzmann',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Schelling - Lehrlingsinitiative',
                text: '\
                    Mit Typo3 wurde eine kompakte Website für die Schelling Lehrlingsinitiative umgesetzt.\
                    Natürlich mit Bewerbungsformular, so daß die zukünftigen Lehrlinge auf einfachem Weg Kontakt aufnehmen können.\
                ',
                link: 'https://www.ibinanschelling.com/',
                image: 'images/references/reference-schelling.jpg',
                anchor: 'schelling-lehrlingsinitiative',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#typo3',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Schloss Hofen - Weiterbildung',
                text: '\
                    Typo3\
                ',
                link: 'https://www.schlosshofen.at/',
                image: 'images/references/rock-texture.jpg',
                anchor: 'schloss-hofen-weiterbildung',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#typo3',
                        category: 'technology'
                    }
                ]
            },*/
            /*{
                title: 'Skulpturen Mittendorfer',
                text: '\
                    Mit einer kleinen Künstlerwebsite basierend auf BREAK.Fast hat Skulpturen Mittendorfer eine ansprechende Webpräsenz bekommen.\
                    Das Design basiert auf dem universell einsetzbaren Bootstrap Frontend Framework.\
                ',
                link: 'https://www.skulpturen-mittendorfer.at/',
                image: 'images/references/reference-skulpturen-mittendorfer.jpg',
                anchor: 'skulpturen-mittendorfer',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            {
                title: 'Sporthotspot',
                text: `
                    Der Kunde hatte schon selbst einen Webshop mit Wordpress & WooCommerce erstellt.
                     Allerdings war der Shop sehr unübersichtlich, da mit den Grundfunktionen nur begrenzte Möglichkeiten zur Gestaltung und Strukturierung bestehen.
                      Auch hier bietet der Page Builder vom Theme Avada umfangreiche Möglichkeiten, um sowohl statische als auch dynamische Inhalte komplett zu gestalten.
                       Dazu helfe ich meinen Kunden eine sinnvolle Struktur der Inhalte zu finden.
                `,
                link: 'https://www.sporthotspot.de/',
                image: 'images/references/reference-sporthotspot.webp',
                anchor: 'sporthotspot',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },   
            /*{
                title: 'Supervisionstagung',
                text: '\
                    BREAK.Fast\
                ',
                link: 'https://www.supervisionstagung.at/',
                image: 'images/references/rock-texture.jpg',
                anchor: 'supervisionstagung',
                tags: [
                    {
                        title: '#bootstrap',
                        category: 'technology'
                    },
                    {
                        title: '#breakfast',
                        category: 'technology'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    }
                ]
            },*/
            /*{
                title: 'Tipkom - Werbedisplays',
                text: '\
                    Magento\
                ',
                link: 'https://www.tipkom.com/',
                image: 'images/references/rock-texture.jpg',
                anchor: 'tipkom-werbedisplays',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#magento',
                        category: 'technology'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    }
                ]
            },*/
            {
                title: 'treibstoff Kostümfundus',
                text: `
                    Auf den ersten Blick ist die Webseite für den treibstoff Kostümfundus nur eine Platzhalterseite für einen nachhaltigen Kostümverleih.
                     Im Hintergrund läuft aber WooCommerce als Kassensystem für die Mitarbeiter.
                      So können Sie ohne separate Software Inventar & Kunden verwalten, Ausleihen & Rückgaben dokumentieren und einen Spendenrechner gibt es auch.
                `,
                link: 'https://treibstoff-fundus.de/',
                image: 'images/references/reference-treibstoff.webp',
                anchor: 'treibstoff',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Unikat Metzler - Krabbelschuhe',
                text: `
                    Für die handgemachten Krabbelschuhe von Unikat Metzler habe ich ein Design nach Vorgaben der Kundin umgesetzt.
                     Im Webshop selbst ist es möglich die individualisierbaren Krabbelschuhe direkt zu bestellen.
                      Es wird Größe, Personalisierung und Sohlenart eingestellt und direkt der passende Preis ausgerechnet.
                       Das ist einfach und bequem für die Kund:innen und für die Schuhmacherin.
                `,
                link: 'https://unikat-metzler.at/',
                image: 'images/references/reference-unikat-metzler.webp',
                anchor: 'unikat-metzler',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'V-Consulting - Die Beratungsmanufaktur',
                text: `
                    Die Unternehmensberatung V-Cosnulting wollte ihren Webauftritt modernisieren.
                     Auch hier gab es einen Wechsel von einem Online-Baukasten auf Wordpress mit Avada.
                      Das Design besticht mit großen Weißräumen, professionellen Fotos und individuellen Icons (tolle Arbeit, Sophie).
                       Die Inhalte können vom Kunden aktualisiert werden, so das Seminaredaten und Blog-Beiträge immer auf dem neuesten Stand sind.
                `,
                link: 'https://v-consulting.at/',
                image: 'images/references/reference-vconsulting.webp',
                anchor: 'vconsulting',
                tags: [
                    {
                        title: '#blog',
                        category: 'type'
                    },
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type',
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'VFM Versicherungsagentur',
                text: `
                    Auch bei Gewerken bei denen es schwer ist Fotos zu machen, kann eine ansprechende Webseite gestaltet werden.
                     Für die VFM Versicherungsagentur habe ich die Webseite mit einer Mischung aus Stock-Fotos und Fotos der Versicherungen illustriert.
                      Ansonsten gibt ein klares Design Übersicht über die Dienstleistungen und andere wichtige Informationen.
                `,
                link: 'https://vfm.at/',
                image: 'images/references/reference-vfm.webp',
                anchor: 'vfm',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Vinothek Jenny',
                text: `
                    Bei der Vinothek Jenny gibt es seit 1979 feinste Weine und andere Delikatessen.
                     Die neue Inhaberin wollte der traditionellen Vinothek, aber eine moderne Webseite verpassen.
                      Versuche mit Online-Baukästen führten nicht zum gewünschten Ergebnis, also haben wir eine Wordpress-Webseite mit einem vorgefertigten Design umgesetzt.
                `,
                link: 'https://vinothek-jenny.at/',
                image: 'images/references/reference-vinothek-jenny.webp',
                anchor: 'vinothek-jenny',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type',
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            {
                title: 'VSSÖ - Zuchtverein Schweizer Sennenhunde',
                text: `
                    Der Verein für Schweizer Sennenhunde in Österreich wollte den Webauftritt erneuern und Prozesse digitalisieren.
                     Dafür haben wir ein ausführliches Portfolio aller Mitglieder inkl. Hunde erstellt.
                      Zusätzlich gibt es Veranstaltungen inkl. Anmeldung und unterschiedliche Formulare für Mitgliedsanträge, Zuchtmeldungen, etc. 
                       Und der interne Bereich erlaubt es Inhalte nur für Mitglieder zur Verfügung zu stellen.
                `,
                link: 'https://vssoe.at/',
                image: 'images/references/reference-vssoe.webp',
                anchor: 'vssoe',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#booking',
                        category: 'type'
                    },
                    {
                        title: '#intranet',
                        category: 'type'
                    },
                    {
                        title: '#portfolio',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Zahnarzt Bargon',
                text: `
                    Die meisten themen-spezifischen Themes werden nur einige Jahre entwickelt und bekommen dann keine Updates mehr.
                     Das ist bei Zahnarzt Bargon passiert. Es haben sich mehr und mehr Fehler eingeschlichen und irgendwann konnten Änderungen nur noch über HTML-Code eingebaut werden.
                      Beim Relaunch setze ich auf das Universal-Theme Avada. Es bringt umfangreiche Funktionen wie Page Builder, Portfolio, Formulare, etc. mit.
                       So kann ohne Zusatzplugins eine Webseite mit den üblichen Funktionen gebaut werden.
                `,
                link: 'https://zahnarzt-bargon.at/',
                image: 'images/references/reference-zahnarzt-bargon.webp',
                anchor: 'zahnarzt-bargon',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#portfolio',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type',
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            {
                title: 'Zapfig Living',
                text: '\
                    Die bestehende Webseite von Zapfig Living wurde auf Wordpress migriert und das Design von Winderwild übernommen.\
                    Außerdem wurde die Buchungssoftware von Zimmersoftware direkt eingebunden und optisch angepasst.\
                ',
                link: 'https://zapfig-living.at/',
                image: 'images/references/reference-zapfigliving.webp',
                anchor: 'zapfigliving',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#booking',
                        category: 'type'
                    },
                    {
                        title: '#relaunch',
                        category: 'type',
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },
            /*{
                title: 'Zapfig Shop',
                text: '\
                    Ein moderner Webshop wurde mit Hilfe des Designleitfadens von Winderwild erstellt und in das Zapfig Ökosystem eingefügt.\
                    Mit Hilfe der Ländlekassa werden alle Geschäftfelder im Hintergrund verwaltet (Zimmervermietung, Fitnessstudio, Brotbestellung, Webshop).\
                ',
                link: 'https://zapfig-shop.at/',
                image: 'images/references/reference-zapfigshop.webp',
                anchor: 'zapfigshop',
                tags: [
                    {
                        title: '#cms',
                        category: 'type'
                    },
                    {
                        title: '#webshop',
                        category: 'type'
                    },
                    {
                        title: '#avada',
                        category: 'technology'
                    },
                    {
                        title: '#laendlekassa',
                        category: 'technology'
                    },
                    {
                        title: '#woocommerce',
                        category: 'technology'
                    },
                    {
                        title: '#wordpress',
                        category: 'technology'
                    }
                ]
            },*/
            /*Soulhelper, Tschenett WP websites, DIA, FSD, Ina Wolf, Lila Laune*/
        ]
    })
};
</script>
